<template>
    <div class="col-12 grid-margin">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Create a New Room</h4>
                <p class="card-description">
                    {{ roomId }}
                </p>
                <form @submit.prevent="create" class="forms-sample">
                    <b-form-group label="" label-for="room">
                        <b-form-input v-model="roomName" type="text" id="room" placeholder="Enter Room Name"></b-form-input>
                    </b-form-group>
                    <b-button type="submit" variant="success" class="mr-2">Create</b-button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";


export default {
    data() {
        return {
            roomName: null,
            roomId: null,

            arg: {},
            welcomeMessage: null,
            players: [],
            sortBy: "name",
            perPage: 10,
            currentPage: 1,
            sortDesc: false,
            sortByFormatted: true,
            filterByFormatted: true,
            filter: "",
            sortable: true,
            fields: [
                { key: "playerName", sortable: true },
                { key: "job", sortable: true },
                { key: "annualSalary", sortable: true },
                { key: "grossMonthlySalary", sortable: true },
                { key: "netMonthlySalary", sortable: true },
            ],
            items: [],
            noItems: null,
        };
    },
    computed: {
        ...mapGetters(["getCurrentUser", "getRoom"]),
        rows() {
            return this.items.length;
        },
    },
    methods: {
        ...mapActions([
            "createRoom",
        ]),
        async create() {
            let response = await this.createRoom({ roomName: this.roomName });
            if (response.status == 200) {
                this.$socket.emit("joinRoom", {
                    playerId: this.getCurrentUser.id,
                    roomId: response.data.room.id,
                    isAdmin: true,
                });
                localStorage.setItem('roomId', response.data.room.id);
                this.$router.push({name: 'game-room', params: {id: response.data.room.id}})
            }

            // socket.emit('createRoom', { userName: this.getCurrentUser.userName, roomName: this.roomName });
        },
    },
    created() {
        console.log('socket', this.$socket)
    },
    mounted() {

     },
};
</script>
